import { createContext, useState, useContext, useEffect } from "react";
import { BASE_URL, COLORS } from "../constants";
import { useToasts } from "react-bootstrap-toasts";
import { Button, FloatingLabel, Form, Modal } from "react-bootstrap";
const TOKEN_KEY = "CARUP_TOKEN";

const AuthContext = createContext({});

function AuthProvider({ children }) {
    const toasts = useToasts();
    const [loading, setLoading] = useState(true);
    const [isAuthenticated, setIsAuthenticated] = useState();
    const [token, setToken] = useState();
    const [showProfileModal, setShowProfileModal] = useState(false);
    const [showFreeOrderModal, setShowFreeOrderModal] = useState(false);
    const [userData, setUserData] = useState({});
    const [services, setServices] = useState([]);
    const [form, setForm] = useState({});

    useEffect(() => {
        loadUserData();
    }, [token]);

    async function loadUserData() {
        try {
            const tokenFromStorage = localStorage.getItem(TOKEN_KEY);
            // if (!tokenFromStorage) {
            //     setIsAuthenticated(false);
            //     setLoading(false);
            //     return;
            // }
            setToken(tokenFromStorage);

            const response = await (
                await fetch(`${BASE_URL}/init`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: tokenFromStorage
                            ? `Bearer ${tokenFromStorage}`
                            : undefined,
                    },
                })
            ).json();

            if (response.status === 429) {
                toasts.show({
                    headerContent: "დაფიქსირდა ბევრი მოთხოვნა",
                    bodyContent:
                        "გადატვირთე გვერდი რამდენიმე წუთში, ან განმეორების შემთხვევაში დაგვიკავშირდი",
                    toastProps: {
                        bg: "light",
                        autohide: true,
                    },
                });
                setTimeout(() => {
                    window.location.replace(window.location.href);
                }, 60_000);
            } else {
                if (response?.success) {
                    // Store services
                    setServices(response?.data?.services);

                    // Handle authentication related state
                    const isAuthenticated = !!response?.data?.userData;
                    setIsAuthenticated(isAuthenticated);
                    if (isAuthenticated) {
                        setUserData(response?.data?.userData);
                        if (
                            !response.data.userData.firstName ||
                            !response.data.userData.lastName
                        ) {
                            setShowProfileModal(true);
                        }
                        if (response.data.userData.freeOrdersCount > 0) {
                            setShowFreeOrderModal(true);
                        }
                    }
                } else {
                    setIsAuthenticated(false);
                }
                setLoading(false);
            }
        } catch (error) {
            toasts.show({
                headerContent: "დაფიქსირდა ხარვეზი",
                bodyContent:
                    "გადატვირთე გვერდი რამდენიმე წუთში, ან განმეორების შემთხვევაში დაგვიკავშირდი",
                toastProps: {
                    bg: "light",
                    autohide: true,
                },
            });
            setTimeout(() => {
                window.location.replace(window.location.href);
            }, 60_000);
        }
    }

    async function saveToken(token) {
        try {
            setToken(token);
            localStorage.setItem(TOKEN_KEY, token);
        } catch (error) {
            console.error(error);
        }
    }

    async function removeToken() {
        try {
            setToken(undefined);
            localStorage.removeItem(TOKEN_KEY);
        } catch (error) {
            console.error(error);
        }
    }

    async function updateProfile(event, formOverride) {
        try {
            if (event) {
                event?.preventDefault();
            }

            const response = await (
                await fetch(`${BASE_URL}/user`, {
                    method: "PUT",
                    body: JSON.stringify(formOverride || form),
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                })
            ).json();

            if (!response?.success && response?.error) {
                return toasts.show({
                    headerContent: "დაფიქსირდა ხარვეზი",
                    bodyContent: `${response.error}. სცადე რამდენიმე წუთში, ან განმეორების შემთხვევაში დაგვიკავშირდი`,
                    toastProps: {
                        bg: "light",
                        autohide: true,
                    },
                });
            }

            if (showProfileModal) setShowProfileModal(false);
            if (response?.success) await loadUserData();
        } catch (error) {
            toasts.show({
                headerContent: "დაფიქსირდა ხარვეზი",
                bodyContent:
                    "სცადე რამდენიმე წუთში, ან განმეორების შემთხვევაში დაგვიკავშირდი",
                toastProps: {
                    bg: "light",
                    autohide: true,
                },
            });
        }
    }

    return (
        <AuthContext.Provider
            value={{
                loading,
                isAuthenticated,
                userData,
                services,
                token,
                updateProfile,
                saveToken,
                removeToken,
                loadUserData,
            }}
        >
            {/* Profile modal */}
            <Modal
                centered
                show={showProfileModal}
                fullscreen="sm-down"
                onHide={() => setShowProfileModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>შეიყვანე პირადი ინფორმაცია</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={updateProfile}>
                        {/* First name (required) */}
                        {!userData?.firstName ? (
                            <FloatingLabel
                                className="mb-3"
                                controlId="firstName"
                                label="სახელი (სავალდებულო)"
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="სახელი"
                                    onChange={(event) =>
                                        setForm((prevForm) => {
                                            return {
                                                ...prevForm,
                                                firstName: event.target.value,
                                            };
                                        })
                                    }
                                    value={form.firstName}
                                />
                            </FloatingLabel>
                        ) : null}

                        {/* Last name (required) */}
                        {!userData?.lastName ? (
                            <FloatingLabel
                                className="mb-3"
                                controlId="lastName"
                                label="გვარი (სავალდებულო)"
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="გვარი"
                                    onChange={(event) =>
                                        setForm((prevForm) => {
                                            return {
                                                ...prevForm,
                                                lastName: event.target.value,
                                            };
                                        })
                                    }
                                    value={form.lastName}
                                />
                            </FloatingLabel>
                        ) : null}

                        {/* Email */}
                        {!userData?.email ? (
                            <FloatingLabel
                                className="mb-4"
                                controlId="email"
                                label="ელ. ფოსტა"
                            >
                                <Form.Control
                                    type="email"
                                    placeholder="ელ. ფოსტა"
                                    onChange={(event) =>
                                        setForm((prevForm) => {
                                            return {
                                                ...prevForm,
                                                email: event.target.value,
                                            };
                                        })
                                    }
                                    value={form.email}
                                />
                            </FloatingLabel>
                        ) : null}

                        {/* Submit button */}
                        <div className="d-grid gap-2 mb-1 shadow-sm">
                            <Button
                                variant="primary"
                                size="lg"
                                type="submit"
                                disabled={
                                    (!userData?.firstName &&
                                        !form?.firstName) ||
                                    (!userData?.lastName && !form?.lastName)
                                }
                                style={{
                                    backgroundColor: COLORS.PRIMARY.BLUE,
                                    borderColor: COLORS.PRIMARY.BLUE,
                                }}
                            >
                                შენახვა
                            </Button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            {/* Free order modal */}
            <Modal
                centered
                show={showFreeOrderModal}
                fullscreen="sm-down"
                onHide={() => setShowFreeOrderModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>
                        <h5>საჩუქარი ქარაფისგან! 🎁</h5>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        აირჩიე ნებისმიერი მომსახურება და გამოიძახე უფასოდ,
                        საჩუქრად ქარაფისგან! 🎉
                    </p>
                    <p>არ დაგავიწყდეს მოუყვე მეგობრებს ქარაფის შესახებ 😊 ✨</p>
                    {/* <Form onSubmit={updateProfile}> */}
                    {/* <div className="d-grid gap-2 mb-1 shadow-sm">
                            <Button
                                variant="primary"
                                size="lg"
                                type="submit"
                                style={{
                                    backgroundColor: COLORS.PRIMARY.BLUE,
                                    borderColor: COLORS.PRIMARY.BLUE,
                                }}
                            >
                                შეკვეთა
                            </Button>
                        </div> */}
                    {/* </Form> */}
                </Modal.Body>
            </Modal>
            {children}
        </AuthContext.Provider>
    );
}

function useAuth() {
    return useContext(AuthContext);
}

export { AuthContext, AuthProvider, useAuth };
