import { Link } from "react-router-dom";
import Ratio from "react-bootstrap/Ratio";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { BASE_URL, COLORS } from "../../constants";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import "./style.css";
import Packages from "./Packages";
import { Card } from "react-bootstrap";
import Rating from "../../components/Rating";

export default function Home() {
    const [testimonials, setTestimonials] = useState([]);
    const navigate = useNavigate();
    const { t } = useTranslation();

    useEffect(() => {
        fetchTestimonials();
    }, []);

    async function fetchTestimonials() {
        try {
            const response = await (
                await fetch(`${BASE_URL}/testimonials`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                    },
                })
            ).json();
            if (response?.success && response?.data) {
                setTestimonials(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <div>
            <Container className="mt-5">
                <Row>
                    <Col
                        xs={0}
                        md={6}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            flexDirection: "column",
                        }}
                    >
                        <h1
                            className="mb-3 fw-bold"
                            style={{
                                color: COLORS.PRIMARY.BLUE,
                            }}
                        >
                            ქარაფი
                        </h1>
                        <h2
                            className="mb-4 text-center"
                            style={{
                                color: COLORS.PRIMARY.BLUE,
                            }}
                        >
                            {t("coming-soon.title")}
                        </h2>
                        <div className="d-grid gap-2 mb-4 shadow-sm">
                            <Button
                                size="lg"
                                variant="primary"
                                style={{
                                    backgroundColor: COLORS.PRIMARY.TURQOISE,
                                    borderColor: COLORS.PRIMARY.TURQOISE,
                                    fontSize: "1.7rem",
                                }}
                                onClick={() => navigate("/order")}
                            >
                                გამოიძახე 🚀
                            </Button>
                        </div>
                    </Col>
                    <Col>
                        <Steps />
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col xs={12}>
                        <Ratio aspectRatio="16x9">
                            <iframe
                                src="https://www.youtube-nocookie.com/embed/QvQfRDWkEx0?si=lOvpQ4sGiQpr_uGs"
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                allowFullScreen
                            ></iframe>
                        </Ratio>
                    </Col>
                </Row>
                <Packages />
                <Testimonials testimonials={testimonials} />
                <OurMission />
            </Container>
        </div>
    );
}

function Steps() {
    return (
        <ul className="steps">
            <li>
                <div className="ms-2 me-auto">
                    <div className="fw-bold lead">გამოიძახე</div>
                    <ul>
                        <li>უთითებ ავტომობილის მონაცემებს.</li>
                        <li>ირჩევ შენთვის სასურველ დროსა და მისამართს.</li>
                        <li>
                            იხდი თანხას, ელოდები შეკვეთის შესრულებას და თვალს
                            ადევნებ პროცესს.
                        </li>
                    </ul>
                </div>
            </li>
            <li>
                <div className="ms-2 me-auto">
                    <div className="fw-bold lead">
                        მიიღე მომსახურება სახლიდან გაუსვლელად
                    </div>
                    <ul>
                        <li>
                            ჩვენი პარტნიორი მოდის შენს მიერ მითითებულ
                            მისამართზე, შერჩეულ დროის მონაკვეთში.
                        </li>
                        <li>
                            რეცხავს ავტომობილს იქ სადაც დაპარკინგებულია, შენი
                            დახვედრის ან ავტომობილის გაღების გარეშე.
                        </li>
                        <li>ასრულებს შეკვეთას და ასურათებს.</li>
                    </ul>
                </div>
            </li>
        </ul>
    );
}

function Testimonials({ testimonials }) {
    if (!testimonials) return;
    return (
        <Row className="mt-5">
            <h1 className="text-center">შეფასებები</h1>

            {testimonials.map((testimonial, index) => (
                <Col md={6} lg={4} key={index} className="testimonials mt-3">
                    <Card className="testimonials-item text-center">
                        <Card.Body>
                            <figure>
                                <blockquote className="fs-6 blockquote">
                                    <p>{testimonial.feedback}</p>
                                </blockquote>
                                <figcaption className="fs-5 blockquote-footer">
                                    {testimonial.name.includes(" ")
                                        ? `${testimonial.name}.`
                                        : testimonial.name}
                                </figcaption>
                                <Rating
                                    isReadOnly={true}
                                    size={25}
                                    initialRating={testimonial.rating}
                                />
                            </figure>
                        </Card.Body>
                    </Card>
                </Col>
            ))}
        </Row>
    );
}

function OurMission() {
    const [isActive, setIsActive] = useState(false);

    return (
        <Row className="mt-5">
            <Col></Col>
            <Col xs={10} md={9}>
                <h1 className="text-center">ჩვენი მისია</h1>
                <h5 className="mt-3">
                    ჩვენი მისიაა მოვახდინოთ გარდატეხა და ციფრული ტრანსფორმაცია
                    ავტომობილის გარეცხვის მომსახურების ინდუსტრიაში. ვაქციოთ
                    მომხმარებლისთვის ავტომობილის გარეცხვა კომფორტულ პროცესად,
                    სახლიდან/სამსახურიდან გაუსვლელად.
                </h5>

                <Link
                    href="#"
                    onClick={() => setIsActive((prevIsActive) => !prevIsActive)}
                >
                    <h4 className="mt-3" data-toggle={isActive}>
                        ჩვენი ღირებულებები{" "}
                        <img
                            className="accordion-img"
                            alt="Chevron down"
                            src="/images/chevron-down.svg"
                        />
                    </h4>
                </Link>
                <ul
                    className={`mt-2 accordion ${
                        isActive
                            ? "accordion-content-open"
                            : "accordion-content-closed"
                    }`}
                >
                    <div>
                        <li>
                            <strong>მომსახურების მოქნილობა</strong> - ჩვენ
                            მომხმარებელს ვემსახურებით ნებისმიერ მისამართზე,
                            მისთვის ხელსაყრელ დროს.
                        </li>
                        <li className="mt-2">
                            <strong>მომხმარებლის კომფორტი</strong> - ჩვენი
                            მომსახურებით მომხმარებელს ვთავაზობთ ავტომობილის
                            ტრადიციული მეთოდით გარეცხვის კომფორტულ ალტერნატივას,
                            სახლიდან გაუსვლელად, რის შედეგადაც მომხმარებელი
                            ზოგავს დროს, საწვავს და ძალისხმევას.
                        </li>
                        <li className="mt-2">
                            <strong>მაღალი ხარისხი</strong> - ვიყენებთ პრემიუმ
                            ხარისხის პროდუქტებსა და ხელსაწყოებს, და გვაქვს
                            მრავალ ეტაპიანი ხარისხის კონტროლის პროცესი.
                        </li>
                    </div>
                    <div>
                        <li className="mt-2">
                            <strong>გარემოზე ზრუნვა</strong> - ვიყენებთ
                            არატოქსიკურ პროდუქტებსა და ვზოგავთ წყალს, რის
                            შედეგადაც მინიმუმამდე დაგვყავს გარემოზე ზემოქმედება.
                        </li>
                        <li className="mt-2">
                            <strong>მუდმივი განვითარება</strong> - ჩვენი
                            მომსახურების დასახვეწად უწყვეტად ვახორციელებთ
                            კვლევასა და განვითარებას.
                        </li>
                    </div>
                </ul>
            </Col>
            <Col></Col>
        </Row>
    );
}
