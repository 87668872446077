import { Link, useParams, useSearchParams } from "react-router-dom";
import { BASE_URL, COLORS, TIME_SLOTS } from "../../constants";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Stepper from "./Stepper";
import { useAuth } from "../../contexts/Authentication";
import NotFound from "../../components/NotFound";
import Loader from "../../components/Loader";
import Rating from "../../components/Rating";
import { useToasts } from "react-bootstrap-toasts";
import { FloatingLabel } from "react-bootstrap";
import PaymentMethod from "../../components/PaymentMethod";
import { getStatusLog } from "../../utils/statusLog.util";

export default function OrderDetails() {
    const toasts = useToasts();
    const { i18n } = useTranslation();
    const { id } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const statusQueryParam = searchParams.get("status");
    const { token, userData, services } = useAuth();
    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState();
    const [rating, setRating] = useState();
    const [feedback, setFeedback] = useState();
    const [isPayButtonDisabled, setPayButtonDisabled] = useState(false);
    const [statusLog, setStatusLog] = useState([]);

    useEffect(() => {
        fetchOrder();
    }, []);

    useEffect(() => {
        if (order) {
            setStatusLog(getStatusLog(order));
        }
    }, [order]);

    async function fetchOrder() {
        try {
            const response = await (
                await fetch(
                    `${BASE_URL}/order/${id}?lang=${i18n.language || "ka"}`,
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
            ).json();

            if (response?.success && response?.data) setOrder(response.data);
        } catch (error) {
            console.log(error);
            toasts.show({
                headerContent: "დაფიქსირდა ხარვეზი",
                bodyContent:
                    "სცადე რამდენიმე წუთში, ან განმეორების შემთხვევაში დაგვიკავშირდი",
                toastProps: {
                    bg: "light",
                    autohide: true,
                },
            });
        } finally {
            setLoading(false);
        }
    }

    async function rateOrder() {
        if (!rating) return;
        try {
            const response = await (
                await fetch(`${BASE_URL}/order/${id}/rate`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({
                        rating,
                        feedback,
                    }),
                })
            ).json();

            if (response?.success) fetchOrder();
        } catch (error) {
            toasts.show({
                headerContent: "დაფიქსირდა ხარვეზი",
                bodyContent:
                    "სცადე რამდენიმე წუთში, ან განმეორების შემთხვევაში დაგვიკავშირდი",
                toastProps: {
                    bg: "light",
                    autohide: true,
                },
            });
        }
    }

    async function payOrder() {
        if (order.paid) return;
        try {
            setPayButtonDisabled(true);
            const response = await (
                await fetch(`${BASE_URL}/order/${id}/pay`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                })
            ).json();

            if (!response?.success && response?.error) {
                return alert(response.error);
            }

            // Redirect to payment page
            if (response?.success || response?.data) {
                if (response?.data?.paymentUrl) {
                    window.location.href = response.data?.paymentUrl;
                    return;
                }
            }
        } catch (error) {
            toasts.show({
                headerContent: "დაფიქსირდა ხარვეზი",
                bodyContent:
                    "სცადე რამდენიმე წუთში, ან განმეორების შემთხვევაში დაგვიკავშირდი",
                toastProps: {
                    bg: "light",
                    autohide: true,
                },
            });
        } finally {
            setPayButtonDisabled(false);
        }
    }

    if (!loading && !order) {
        return <NotFound text="ასეთი შეკვეთა არ არსებობს" />;
    }

    if (loading) {
        return <Loader />;
    }

    return (
        <div>
            <Container className="mt-5">
                <h2 className="mb-5 fw-bold text-center">შეკვეთის დეტალები</h2>

                {/* Payment callback status */}
                {["successful", "failed"].includes(statusQueryParam) && (
                    <Alert
                        variant={
                            statusQueryParam === "successful"
                                ? "success"
                                : "danger"
                        }
                        className="text-center"
                    >
                        {statusQueryParam === "successful"
                            ? "გადახდა წარმატებულია! 🎉"
                            : ""}
                        {statusQueryParam === "failed"
                            ? "გადახდა წარუმატებელია. სცადე კიდევ ერთხელ, დარწმუნდი რომ ბარათზე გაქვს საკმარისი თანხა და სწორად შეგყავს ბარათის მონაცემები."
                            : ""}
                    </Alert>
                )}

                <Row>
                    {/* Left column */}
                    <Col xs={0} lg={6}>
                        {/* Rate completed order */}
                        {order.completed ? (
                            <Card
                                bg="light"
                                text="dark"
                                className="mb-3 text-center"
                            >
                                {!order.rating && (
                                    <Card.Header>
                                        შეაფასე დღეს მიღებული მომსახურება
                                    </Card.Header>
                                )}
                                <Card.Body>
                                    <Rating
                                        isReadOnly={order.rating}
                                        initialRating={order.rating}
                                        handleOnClick={(newRating) => {
                                            setRating(newRating);
                                        }}
                                    />

                                    {/* Comment */}
                                    <FloatingLabel
                                        className="mt-3 mb-3"
                                        controlId="comment"
                                        label="კომენტარი (არასავალდებულო)"
                                    >
                                        <Form.Control
                                            as="textarea"
                                            type="text"
                                            disabled={order.feedback}
                                            onChange={(event) =>
                                                setFeedback(event.target.value)
                                            }
                                            value={order.feedback || feedback}
                                            style={{ height: "100px" }}
                                        />
                                    </FloatingLabel>

                                    {/* Note for satisfied customers to leave feedback on our FB */}
                                    {/* {rating === 5 ? (
                                        <p
                                            style={{
                                                fontSize: "12px",
                                            }}
                                        >
                                            გვიხარია რომ კმაყოფილი ხარ ჩვენი
                                            მომსახურებით. არ დაგავიწყდეს
                                            დაგვიტოვო შეფასება Facebook-ზეც:{" "}
                                            <Link
                                                to="https://www.facebook.com/carup.ge/reviews"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                className="text-decoration-underline"
                                            >
                                                https://facebook.com/carup.ge/reviews
                                            </Link>
                                        </p>
                                    ) : null} */}

                                    {/* Submit button */}
                                    {!order.rating && (
                                        <div className="d-grid gap-2 shadow-sm">
                                            <Button
                                                onClick={() => rateOrder()}
                                                variant="primary"
                                                size="md"
                                                type="submit"
                                                style={{
                                                    backgroundColor:
                                                        COLORS.PRIMARY.BLUE,
                                                    borderColor:
                                                        COLORS.PRIMARY.BLUE,
                                                }}
                                                disabled={
                                                    order.rating || !rating
                                                }
                                            >
                                                ✨ გაგზავნა ✨
                                            </Button>
                                        </div>
                                    )}
                                </Card.Body>
                            </Card>
                        ) : null}

                        {/* Pay for unpaid order */}
                        {!order.paid ||
                        (statusQueryParam && statusQueryParam === "failed") ? (
                            <Card
                                bg="light"
                                text="dark"
                                className="mb-3 text-center"
                            >
                                <Card.Body>
                                    <PaymentMethod
                                        props={{
                                            savedCard: userData?.savedCard,
                                        }}
                                    />
                                    <div className="d-grid gap-2 shadow-sm">
                                        <Button
                                            onClick={() => payOrder()}
                                            disabled={isPayButtonDisabled}
                                            variant="primary"
                                            size="md"
                                            type="submit"
                                            style={{
                                                backgroundColor:
                                                    COLORS.PRIMARY.BLUE,
                                                borderColor:
                                                    COLORS.PRIMARY.BLUE,
                                            }}
                                        >
                                            გადახდა
                                        </Button>
                                    </div>
                                </Card.Body>
                            </Card>
                        ) : null}

                        {/* Status log */}
                        <Card bg="light" text="dark" className="mb-3">
                            <Card.Body>
                                <Stepper isVertical content={statusLog} />
                            </Card.Body>
                        </Card>
                    </Col>

                    {/* Right column */}
                    <Col>
                        <Card bg="light" text="dark">
                            <Card.Body>
                                <Form.Group className="mb-3">
                                    <Form.Label>შეკვეთის ID</Form.Label>
                                    <Form.Control
                                        readOnly
                                        type="text"
                                        value={order._id}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>მწარმოებელი</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        value={order.make}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>მოდელი</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        value={order.model}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        სახელმწიფო სანომრე ნიშანი
                                    </Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        value={order.licensePlate}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>გარეცხვის დრო</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        value={`${new Date(
                                            order.date
                                        ).toLocaleDateString()} ${
                                            TIME_SLOTS.find(
                                                (i) => i.value === order.time
                                            ).name
                                        }`}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>მისამართი</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        value={order.address}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>
                                        ზუსტი ადგილმდებარეობა / მინიშნება
                                    </Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        value={order.comment}
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>მომსახურება</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        value={
                                            services.find(
                                                (service) =>
                                                    service._id ===
                                                    order.serviceId
                                            )?.title || ""
                                        }
                                    />
                                </Form.Group>

                                <Form.Group className="mb-3">
                                    <Form.Label>ფასი</Form.Label>
                                    <Form.Control
                                        disabled
                                        type="text"
                                        value={`${order.price} ₾`}
                                    />
                                </Form.Group>

                                {/* <div className="d-grid gap-2 mb-1 mt-3 shadow-sm">
                                    <Button
                                        variant="primary"
                                        size="lg"
                                        type="submit"
                                        style={{
                                            backgroundColor:
                                                COLORS.PRIMARY.BLUE,
                                            borderColor: COLORS.PRIMARY.BLUE,
                                        }}
                                        disabled={
                                            !make.length ||
                                            !type.length ||
                                            !licensePlate.length ||
                                            !address.length ||
                                            !date.length ||
                                            !firstName.length ||
                                            !acceptedTerms
                                        }
                                    >
                                        გაგრძელება
                                    </Button>
                                </div> */}
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}

// export function STATUS_TO_TRANSLATION(status) {
//     switch (status) {
//         case "REQUESTED":
//             return "შეკვეთა განხილვის პროცესშია";
//         case "PAID":
//             return "თანხა გადახდილია";
//         case "ASSIGNED":
//             return "შეკვეთა მიენიჭა შემსრულებელს";
//         case "IN_PROGRESS":
//             return "შეკვეთა პროგრესშია";
//         case "COMPLETED":
//             return "შეკვეთა დასრულებულია";
//         case "REJECTED":
//             return "შეკვეთა უარყოფილია";
//         case "REFUNDED":
//             return "თანხა დაბრუნებულია";
//         case "CANCELED":
//             return "შეკვეთა გაუქმებულია";
//         default:
//             return "-";
//     }
// }

{
    /* <Col md={12} lg={5}>
                        <Card bg="light" text="dark" className="mb-3">
                            <Alert
                                key="danger"
                                variant="danger"
                                className="text-center"
                            >
                                გადაიხადე მომსახურების საფასური იმისთვის რომ
                                მოხდეს შეკვეთის შესრულება
                            </Alert>
                            <Card.Body>
                                <Row xs={1} md={1} className="g-4">
                                    <Col>
                                        <Card
                                            bg="light"
                                            text="dark"
                                            className="mb-3"
                                        >
                                            <Card.Header>
                                                <Row>
                                                    <Col>გარედან გარეცხვა</Col>
                                                    <Col className="text-end fw-bold text-danger">
                                                        გადასახდელი
                                                    </Col>
                                                </Row>
                                            </Card.Header>

                                            <Card.Footer>
                                                <Row>
                                                    <Col className="d-flex align-items-center">
                                                        {order.price}{" "}
                                                        {order.currency}
                                                    </Col>
                                                    <Col className="text-end">
                                                        <Button
                                                            variant="success"
                                                            className="fw-bold"
                                                        >
                                                            გადახდა
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            </Card.Footer>
                                        </Card>
                                    </Col>
                                </Row>

                                <StepperComponent
                                    stepperContent={[
                                        {
                                            label: `შეკვეთა შეიქმნა`,
                                            comment:
                                                order.statusLog?.[0]?.timestamp?.slice(
                                                    0,
                                                    10
                                                ),
                                            isError: false,
                                            isComplete: true,
                                        },
                                        {
                                            label: "გადახდა",
                                            isError: false,
                                            isComplete: false,
                                        },
                                        {
                                            label: "შეკვეთა გააქტიურდა",
                                            isError: false,
                                            isComplete: false,
                                        },
                                        {
                                            label: "დაიწყო გარეცხვა",
                                            isError: false,
                                            isComplete: false,
                                        },
                                        {
                                            label: "დასრულდა გარეცხვა",
                                            isError: false,
                                            isComplete: false,
                                        },
                                        {
                                            label: "დაიხურა შეკვეთა ",
                                            isError: false,
                                            isComplete: false,
                                        },
                                    ]}
                                />

                                <div className="d-grid gap-2 mb-1 mt-3 shadow-sm">
                                    <Button
                                        variant="primary"
                                        size="lg"
                                        type="submit"
                                        style={{
                                            backgroundColor:
                                                COLORS.PRIMARY.BLUE,
                                            borderColor: COLORS.PRIMARY.BLUE,
                                        }}
                                        disabled={
                                            !make.length ||
                                            !type.length ||
                                            !licensePlate.length ||
                                            !address.length ||
                                            !date.length ||
                                            !firstName.length ||
                                            !acceptedTerms
                                        }
                                    >
                                        გაგრძელება
                                    </Button>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col> */
}

//     switch (status) {
//         case "REQUESTED":
//             return "შეკვეთა განხილვის პროცესშია"
//         case "PAID":
//             return "თანხა გადახდილია"
//         case "ASSIGNED":
//             return "შეკვეთა მიენიჭა შემსრულებელს"
//         case "IN_PROGRESS":
//             return "შეკვეთა პროგრესშია"
//         case "COMPLETED":
//             return "შეკვეთა დასრულებულია"
//         case "REJECTED":
//             return "შეკვეთა უარყოფილია"
//         case "REFUNDED":
//             return "თანხა დაბრუნებულია"
//         case "CANCELED":
//             return "შეკვეთა გაუქმებულია"
//         default:
//             return "-"
//     }

// [
//     {
//         label: `შეკვეთა შეიქმნა`,
//         comment:
//             order.statusLog?.[0]?.timestamp?.slice(
//                 0,
//                 10
//             ),
//         isError: false,
//         isComplete: true,
//     },
//     {
//         label: "გადახდა",
//         // button: <></>,
//         // isActive: true,
//         // isWarning: true,
//         // isError: false,
//         // isComplete: false,
//     },
//     {
//         label: "შეკვეთა გააქტიურდა",
//         isError: false,
//         isComplete: false,
//     },
//     {
//         label: "დაიწყო გარეცხვა",
//         isError: false,
//         isComplete: false,
//     },
//     {
//         label: "დასრულდა გარეცხვა",
//         isError: false,
//         isComplete: false,
//     },
//     {
//         label: "დაიხურა შეკვეთა ",
//         isError: false,
//         isComplete: false,
//     },
// ]
